.App {
  width: 80%;
  max-width: 1200px;
  margin: auto;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.logo {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.08);
}

.logo-image {
  height: 2.8em;
}

.logo-text {
  font-size: 1.2em;
  font-family: Lato;
  color: #24c8ff;
  margin: 0 8px 0 8px;
}

.header-statement {
  font-family: Lato;
  font-size: 2.5em;
  font-weight: 700;
  padding: 24px 24px 0 24px;
  color: #4d4f51;
}

.header-substatement {
  display: block;
  color: #9b9c9e;
  padding: 0 24px 0 24px;
  font-size: 1.4em;
}

.header-info {
  width: 45%;
  padding: 1em;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

.header-snippet {
  display: inline-block;
  width: 55%;
}

.download-btn {
  background-color: #3fccfc;
  border: none;
  color: white;
  font-size: 1em;
  font-family: Lato;
  border-radius: 12px;
  padding: 0 0.8em 0 2em;
  display: inline-block;
  line-height: 1.4em;
  height: 48px;
  font-weight: bold;
  transition: background-color 0.6s;
  cursor: pointer;
  display: inline-block;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.download-btn:hover {
  background-color: #36aed7;
}
.download-btn:focus {
  outline: 0;
}
.feature-section {
  margin-top: 100px;
}

.feature-snippet {
  width: 40%;
  display: inline-block;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);
}

.feature {
  margin-top: 40px;
}

.feature-info {
  width: 60%;
  vertical-align: top;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

.feature-statement {
  font-family: Lato;
  font-size: 2.2em;
  font-weight: 300;
  padding: 48px 48px 0 48px;
  color: #4d4f51;
}

.feature-substatement {
  display: block;
  color: #9b9c9e;
  padding: 0 48px 0 48px;
  font-size: 1.2em;
}

.privacy-statement {
  font-family: Lato;
  font-size: 2.2em;
  font-weight: 300;
  padding: 48px 48px 0 48px;
  color: #4d4f51;
  text-align: center;
}

.privacy-substatement {
  display: block;
  color: #9b9c9e;
  padding: 0 48px 0 48px;
  font-size: 1.2em;
}

.dropdown-btn{
  background-color: #3fccfc;
  border: none;
  height: 48px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 0.8em;
  color: white;
  padding: 0 1em;
  transition: background-color 0.6s;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.dropdown-downloads {
  display: none;
  position: absolute;
  min-width: 200px;
  left: -200px;
  padding-top: 6px;
}

.dropdown-downloads a {
  background-color: #3fccfc;
  padding: 12px 16px;
  display: block;
  font-family: Lato;
  font-weight: bold;
  min-width: 200px;
  text-decoration: none;
  color: white;
  transition: background-color 0.6s;
  cursor: pointer;
}

.dropdown-downloads a:hover {
  background-color: #36aed7;
}
.dropdown:hover .dropdown-downloads {display: block;}
.dropdown:hover .dropdown-btn {
  background-color: #36aed7;
}

.btn-pair {
  display: block;
  margin: 2em auto;
  text-align: center;
  white-space: nowrap;
}

.tech-section {
  margin: 4em 0;
}

.tech-statement {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #6f7175;
  text-align: center;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tech-credit {
  filter: contrast(0.1);
  max-height: 90px;
  margin: 0 1em;
  max-width: 260px;
}

.window-snippet {
  box-shadow: 0 20px 30px rgba(0,0,0,0.5);
}

.footer-section {
  margin-top: 4em;
  padding: 2em;
}

.footer-text{
  color: gray;
  font-size: 0.8em;
}
